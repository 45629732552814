import { MotionValue, motion, useTransform, useSpring } from 'framer-motion';
import { useCallback, useMemo } from 'react';
import { IPosterProps } from './index';
import style from './index.module.scss';

export default function MainFirstTvBlockPoster(props: {
	item: IPosterProps,
	positionY: MotionValue<number>;
}) {
	const opacity = useTransform(props.positionY, (x) => 1 - x * 2);

	const _anim1x = useSpring(props.item.posStart.x);
	const anim1x = useTransform(_anim1x, (x) => x + '%');
	const _anim1y = useSpring(props.item.posStart.y);
	const anim1y = useTransform(_anim1y, (x) => x + '%');

	const onChange = useCallback((x: number) => {
		console.log('x',x);
		
		const posterBoost = x * 2
		_anim1x.set(getAnim(props.item.posStart.x, props.item.posEnd.x, posterBoost))
		_anim1y.set(getAnim(props.item.posStart.y, props.item.posEnd.y, posterBoost))
	}, []);

	function getAnim(posStart: number, posEnd: number, posterBoost: number) {		
		return (posStart + posEnd) * posterBoost + posStart;
	}

	props.positionY.on('change', onChange);

	return (
		<motion.img
			className={style.poster}
			src={props.item.img}
			initial={{
				opacity: 0,
				x: props.item.posStart.x + '%',
				y: props.item.posStart.y + '%',
				rotate: props.item.posStart.rotate,
			}}
			animate={{
				opacity: 1,
				rotate: 0,
				shapeImageThreshold: 0.5
			}}
			style={{
				opacity: opacity,
				x: anim1x,
				y: anim1y,
			}}
		/>
	);
}
