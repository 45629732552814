import { CSSProperties } from 'react'
import s from './common.module.scss'

export default function MainScreen11CommonDevice(props: {
    title: string
    description: {
        title?: string
        listItem?: string[]
        tooltip?: {
            value: string
            styleIcon?: CSSProperties
        }
        blocks?: {img: string, link: string}[]
    }
    img: {
        src: string
        alt: string
        style?: CSSProperties
        video?: {type: string, src: string}[]
    }
}) {
    return  (
        <div className={s['white-block']}>
            <div className={s['infoblock']}>
                <h2 className={s['wbh2']}>
                    {props.title}
                </h2>

                <div className={s.description}>
                    {props.description.title && (
                        <p>{props.description.title}</p>
                    )}

                    {props.description.listItem?.length && (
                        <ol>
                            {props.description.listItem.map((item, idx) => (
                                <li key={idx} dangerouslySetInnerHTML={{__html: item}}></li>
                            ))}
                        </ol>
                    )}
                </div>

                {props.description.tooltip && (
                    <div className={s.tooltip}>
                        <div className={s.icon} style={props.description.tooltip.styleIcon}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_172_9577)">
                                <path d="M8 9.31337C7.58578 9.31337 7.25 8.97758 7.25 8.56337L7.25001 6.72314C7.25001 6.30893 7.58579 5.97314 8.00001 5.97314C8.41422 5.97315 8.75001 6.30893 8.75001 6.72315L8.75 8.56337C8.75 8.97759 8.41421 9.31337 8 9.31337Z" fill="#284059"/>
                                <path d="M8.0001 10.1731C8.49715 10.1731 8.9001 10.5761 8.9001 11.0731C8.9001 11.5702 8.49715 11.9731 8.0001 11.9731C7.50304 11.9731 7.1001 11.5702 7.1001 11.0731C7.1001 10.5761 7.50304 10.1731 8.0001 10.1731Z" fill="#284059"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.12398 13.8492C2.24873 12.9739 2.18383 12.1019 2.05404 10.3579C2.02039 9.90584 2 9.43949 2 8.97314C2 8.5068 2.02039 8.04045 2.05404 7.58836C2.18383 5.84437 2.24873 4.97237 3.12398 4.09712C3.99923 3.22187 4.87122 3.15698 6.61522 3.02718C7.0673 2.99353 7.53365 2.97314 8 2.97314C8.46635 2.97314 8.9327 2.99353 9.38478 3.02718C11.1288 3.15698 12.0008 3.22187 12.876 4.09712C13.7513 4.97237 13.8162 5.84437 13.946 7.58836C13.9796 8.04045 14 8.5068 14 8.97314C14 9.43949 13.9796 9.90584 13.946 10.3579C13.8162 12.1019 13.7513 12.9739 12.876 13.8492C12.0008 14.7244 11.1288 14.7893 9.38479 14.9191C8.9327 14.9528 8.46635 14.9731 8 14.9731C7.53365 14.9731 7.0673 14.9528 6.61522 14.9191C4.87122 14.7893 3.99923 14.7244 3.12398 13.8492ZM8 13.4731C7.57663 13.4731 7.1479 13.4546 6.72655 13.4232C4.88177 13.2859 4.60488 13.2087 4.18464 12.7885C3.7644 12.3683 3.6872 12.0914 3.5499 10.2466C3.51854 9.82525 3.5 9.39651 3.5 8.97314C3.5 8.54978 3.51854 8.12104 3.5499 7.69969C3.6872 5.85492 3.7644 5.57803 4.18464 5.15778C4.60488 4.73754 4.88177 4.66034 6.72654 4.52304C7.1479 4.49168 7.57663 4.47314 8 4.47314C8.42337 4.47314 8.8521 4.49168 9.27346 4.52304C11.1182 4.66034 11.3951 4.73754 11.8154 5.15778C12.2356 5.57803 12.3128 5.85492 12.4501 7.69969C12.4815 8.12104 12.5 8.54978 12.5 8.97314C12.5 9.39651 12.4815 9.82524 12.4501 10.2466C12.3128 12.0914 12.2356 12.3683 11.8154 12.7885C11.3951 13.2087 11.1182 13.2859 9.27346 13.4232C8.8521 13.4546 8.42337 13.4731 8 13.4731Z" fill="#284059"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_172_9577">
                                <rect width="16" height="16" fill="white" transform="translate(0 0.973145)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className={s.info} dangerouslySetInnerHTML={{__html: props.description.tooltip.value}}></div>
                    </div>
                )}

                {props.description.blocks && (
                    <div className={s.blocks}>
                        {props.description.blocks.map((block, idx) => (
                            <a key={idx}>
                                <img src={block.img}/>
                            </a>
                        ))}
                    </div>
                )}
            </div>
            
            <div className={s['main-img']}>
                <img 
                    style={props.img.style}
                    src={props.img.src} 
                    alt={props.img.alt}
                />

                {props.img.video?.length && (
                    <video
                        preload="auto" 
                        controls={false}
                        autoPlay={true}
                        loop
                        muted
					    playsInline
                    >
                        {props.img.video.map((vid, idx) => (
                            <source 
                                key={idx}
                                src={vid.src} 
                                type={vid.type}
                            />  
                        ))}
                    </video>
                )}
            </div>
        </div>
    )
}