import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import s from './index.module.scss';

export default function DevicesLayout() {
  useEffect(() => {    
    const root = document.querySelector(':root') as any
    root.style.setProperty('--color-primary-bg', '#001424')

    return () => {
      root.style.setProperty('--color-primary-bg', '#000')
    }
  }, [])

  return (
    <div className={s['content-page']}>
      <Outlet/>
    </div>
  );
}