import clsx from "clsx";
import { useState } from "react";
import s from './index.module.scss';

export function ToggleableElement(props: {
    title: string, 
    info: any[],
}) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    function toggle() {
        setIsOpen(!isOpen);
    }

    return (
        <div className={clsx(s.question, isOpen && s.open)}>
            <p className={s['question-p']} onClick={toggle}>
                { props.title }
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="8"
                        viewBox="0 0 16 8">
                    <path
                        d="M5.012,9A1,1,0,0,0,4.3,10.706h0l3.129,3.1C9.583,15.934,10.661,17,12,17s2.417-1.066,4.573-3.2l3.129-3.1A1,1,0,0,0,18.987,9a1.016,1.016,0,0,0-.714.293l-3.129,3.1a28.045,28.045,0,0,1-2.389,2.223c-.517.39-.7.39-.752.39H12c-.054,0-.234,0-.752-.39a28.043,28.043,0,0,1-2.389-2.223l-3.129-3.1h0A1.016,1.016,0,0,0,5.012,9Z"
                        transform="translate(-4 -9)" />
                    </svg>
                </span>
            </p>
            <p className={s['answer-p']}>
                {props.info.map((x, idx) => (
                    <span key={idx} className={s.infoItem}>
                        <span className={s.title}>{x.label}</span>
                        <span>Адрес: {x.address}</span>
                        <span>Телефон: {x.tel}</span>
                        <span>E-mail:: {x.email}</span>
                    </span>
                ))}
            </p>
        </div>
    )
} 