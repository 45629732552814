import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { useStoreCtx } from "src/store";
import { getNoun } from "src/utils/string";
import Breadcrumbs from "../../components/breadcrumbs";
import InfoBlock from "../../components/info-block";
import OrdersBtn from "../../components/orders-btn/orders-btn";
import TvTab1 from "../../components/tv-tabs/tab1";
import TvTab2 from "../../components/tv-tabs/tab2";
import TvTab3 from "../../components/tv-tabs/tab3";
import TvTab4 from "../../components/tv-tabs/tab4";
import WhiteBlock from "../../components/white-block";
import s from './index.module.scss';

export interface IDeviceOption {
  value: string,
  label: string,
  model: string | null,
  price: string | null,
  title: string,
  buttons: {
    text: string,
    url: string,
    backgroundColor: string,
    textColor: string
  }[]
  specs: {
    value: string,
    label: string,
  }[],
  images: {main: string[], thumb: string[]},

  chars: {label: string, value: string}[],
  gift: string | null
}

export const DevicePage = observer((props: {
  selectOptions: IDeviceOption[]
  selectedValue: string
  selectLabel: string
  breadcrumbSteps: {label: string, link: string}[]
  stats: any
  manuals: {title: string, items: {url: string, text: string}[]}[]
  shops: {
    title: string,
    points: {
      title: string,
      description: string,
      url: string,
      img: string
    }[]
  }
}) => {
  const selectedOption = props.selectOptions?.find(x => props.selectedValue === x.value) || props.selectOptions[0];

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.headerBlock}>
          <Breadcrumbs steps={props.breadcrumbSteps}/>
          <OrdersBtn/>
        </div>
        
        <WhiteBlock 
          selectLabel={props.selectLabel}
          selectOptions={props.selectOptions}
          selectedValue={props.selectedValue}
        />
      </div>

      <div className={clsx(s.wrapper, s['wrapper-info'])}>
        <InfoBlock
          tab1={<TvTab1 stats={props.stats}/>}
          tab2={<TvTab2 chars={selectedOption.chars}/>}
          tab3={<TvTab3 manuals={props.manuals}/>}
          tab4={<TvTab4 shops={props.shops}/>}
        />
      </div>
    </>
  );
})