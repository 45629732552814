import './styles/globals.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/layout';
import IndexPage from './pages';
import { SsoAuth } from './components/ssoauth/ssoauth';
import {DevicePage} from './pages/device-page/pages/device';
import { ScrollToTop } from './scroll-to-top';
import DevicesLayout from './pages/device-page/components/layout/layout';
import {DevicesServiceCenterPage} from './pages/device-page/pages/service-center/service-center';
import { TvPage } from './pages/device-page/pages/device/tv/tv';
import { SmartboxPage } from './pages/device-page/pages/device/smartbox/smartbox';
import { DevicesCatalogPage } from './pages/catalog-page';

function App() {

  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route path="/" element={<IndexPage/>}>
            <Route path="ssoauth" element={<SsoAuth/>}/>
          </Route>

          <Route path="catalog" element={<DevicesLayout/>}>
            <Route path="" element={<DevicesCatalogPage/>} />
            <Route path="tv" element={<TvPage/>}/>
            <Route path="smartbox" element={<SmartboxPage/>} />
            <Route path="service-center" element={<DevicesServiceCenterPage/>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
