import s from "./index.module.scss";

export default function TvTab4(props: {
  shops: {
    title: string,
    points: { title: string, description: string, url: string, img: string}[]
  }
}) {
  return (
    <div className={s.wrapper}>
      <h2>Где купить</h2>
      <p>{props.shops.title}</p>

      <div className={s.flexx}>
        {props.shops.points.map((item, idx) => (
          <a href={item.url} target="_blank" className={s['fl-block']} key={idx}>
            <div className={s['fl-block-content']}>
              <div className={s.tgrdf} dangerouslySetInnerHTML={{__html: item.img}}></div>
              <div className={s.wefw}>
                <p className={s.zagwefw}>
                  {item.title}
                </p>
                <p>{item.description}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
