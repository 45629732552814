import clsx from "clsx";
import { motion, MotionValue } from "framer-motion";
import { useEffect, useState } from "react";
import Swiper, { Thumbs } from "swiper";
import MainScreen11CommonDevice from "./devices/common";
import s from './index.module.scss'
import {ReactComponent as TvLogoKion} from '../../../assets/11/tv/logo/KION.svg'
import {ReactComponent as TvLogoLg} from '../../../assets/11/tv/logo/LG.svg'
import {ReactComponent as TvLogoSony} from '../../../assets/11/tv/logo/SONY.svg'
import {ReactComponent as TvLogoSamsung} from '../../../assets/11/tv/logo/SAMSUNG.svg'
import {ReactComponent as TvLogoYandex} from '../../../assets/11/tv/logo/YANDEX.svg'
import {ReactComponent as TvLogoHisense} from '../../../assets/11/tv/logo/HISENSE.svg'
import {ReactComponent as BoxLogoApple} from '../../../assets/11/box/logo/apple.svg'
import {ReactComponent as BoxLogoAndroid} from '../../../assets/11/box/logo/android.svg'
import {ReactComponent as BoxLogoApk} from '../../../assets/11/box/logo/apk.svg'
import {ReactComponent as PhoneLogoAndroid} from '../../../assets/11/phone/logo/android.svg'
import {ReactComponent as PhoneLogoIphone} from '../../../assets/11/phone/logo/iphone.svg'

export default function MainScreen11(props: {
	opacity: MotionValue<number>;
}) {
    const [activeTabIdx, setActiveTabIdx] = useState(0)

    useEffect(() => {
        initSwiper()
    }, [])

    function initSwiper() {
        const thumbsSlider1 = new Swiper(".s1", {
            slidesPerView: 'auto',
            watchSlidesProgress: true,
        });
        new Swiper(".sb1", {
            thumbs: {
                swiper: thumbsSlider1,
            },
            autoHeight: true,
            speed: 600,
            modules: [Thumbs],
        });
      
        const thumbsSlider2 = new Swiper(".s2", {
            slidesPerView: 'auto',
            watchSlidesProgress: true,
        });
        new Swiper(".sb2", {
            thumbs: {
                swiper: thumbsSlider2,
            },
            autoHeight: true,
            speed: 600,
            modules: [Thumbs],
        });
      
        const thumbsSlider3 = new Swiper(".s3", {
            slidesPerView: 'auto',
            watchSlidesProgress: true,
        });
        new Swiper(".sb3", {
            thumbs: {
                swiper: thumbsSlider3,
            },
            autoHeight: true,
            speed: 600,
            modules: [Thumbs],
        });
    }

    return (
        <div className={s.eleven}>
            <motion.div className="sticky-view-sticky" style={{ opacity: props.opacity }}>
                <div id="kion" className={clsx(s['fourth-screen'], s.ger)}>
                    <div className={s.wrapper}>
                        <h2 className={s.h2}>Где еще доступен KION</h2>

                        <div className={s.tabs}>
                            <div className={s.labels}>
                                <div className={clsx(s['tab-label'], s['tab-label-1'], activeTabIdx === 0 && s['tab-active'])} 
                                    onClick={() => setActiveTabIdx(0)}
                                >
                                    Телевизоры
                                </div>

                                <div className={clsx(s['tab-label'], s['tab-label-2'], activeTabIdx === 1 && s['tab-active'] )}
                                    onClick={() => setActiveTabIdx(1)}
                                >
                                    Приставки
                                </div>

                                <div className={clsx(s['tab-label'], s['tab-label-3'], activeTabIdx === 2 && s['tab-active'])}
                                    onClick={() => setActiveTabIdx(2)}>
                                    Телефоны
                                </div>
                            </div>

                            {/* Телевизоры */}
                            <div className={clsx(activeTabIdx !== 0 && 'pseudo-hidden')} id="content-1">
                                {/* Thumbs */}
                                <div className={clsx(s['swiper-container'], s['slider-1'], s['top-thums'], 'swiper-container', 's1')}>
                                    <div className={clsx(s['swiper-wrapper'], 'swiper-wrapper')}>
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <TvLogoKion/>
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <TvLogoLg/>
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <TvLogoSony/>
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <TvLogoSamsung/>
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <TvLogoYandex/>
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <TvLogoHisense/>
                                        </div>
                                    </div>
                                </div>

                                <div className={clsx(s['swiper-container'], s['slider-big-1'], 'swiper-container', 'sb1')}>
                                    <div className={clsx(s['swiper-wrapper'], 'swiper-wrapper')}>
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION" 
                                                description={{
                                                    title: 'Просто включите телевизор KION. Мир с тысячами фильмов и сериалов готов к вашим исследованиям: перед просмотром останется только пройти авторизацию по номеру телефона или через QR в нашем мобильном приложении'
                                                }}
                                                img={{
                                                    src: "/main/11/tv/KION_1x.png",
                                                    alt: "KION",
                                                    video: [
                                                        {type: 'video/webm', src: "/main/1/video_in_tv.webm"},
                                                        {type: 'video/mp4', src: "/main/1/video_in_tv.mp4"},
                                                    ]
                                                }}
                                            />
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="LG" 
                                                description={{
                                                    listItem: [
                                                        "Зайдите в LG Content Store, наберите в поиске «KION» и установите приложение",
                                                        "После установки запустите KION и пройдите простую авторизацию по номеру телефона или через QR в мобильном приложении",
                                                        "Готово. Теперь вы можете наслаждаться любимым контентом на большом экране."
                                                    ],
                                                    tooltip: {value: 'Доступно для телевизоров с WebOS 3.0 и выше'},
                                                }}
                                                img={{
                                                    src: "/main/11/tv/LG_1x.png",
                                                    alt: "LG",
                                                }}
                                            />
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="Sony" 
                                                description={{
                                                    listItem: [
                                                        "В списке приложений выберите «Google Play Store», наберите в поиске «KION» и установите приложение", 
                                                        "После установки запустите KION и войдите под своим номером: проще всего это сделать сканированием QR-кода или ввести номер телефона и получить код в SMS", 
                                                        "Отлично, больше ничего не мешает включить любимый фильм и прекрасно отдохнуть в нашей компании",
                                                    ],
                                                    tooltip: {value: 'Телевизоры с Android TV версии 7.0 и выше'},
                                                }}
                                                img={{
                                                    src: "/main/11/tv/Sony_1x.png",
                                                    alt: "Sony",
                                                }}
                                            />
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION на SAMSUNG" 
                                                description={{
                                                    listItem: [
                                                        "Нажмите на пульте кнопку с домиком и зайдите в Apps",
                                                        "В поиске наберите «KION» и установите приложение",
                                                        "Запустите приложение и познакомьтесь с нами: можно ввести номер телефона или отсканировать QR с помощью приложения на вашем смартфоне или планшете."
                                                    ],
                                                    tooltip: {value: 'Телевизоры с Tizen 2017 года и более новые'},
                                                }}
                                                img={{
                                                    src: "/main/11/tv/Samsung_1x.png",
                                                    alt: "Samsung",
                                                }}
                                            />
                                        </div>
                                        
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION НА ЯНДЕКС" 
                                                description={{
                                                    listItem: [
                                                        "Перейдите к пункту «Приложения»: он внизу экрана рядом со значками «Алисы» и профиля",
                                                        "Найдите иконку с надписью «KION», нажмите на неё, затем начните установку",
                                                        "После установки приложения останется только запустить его и войти в свой аккаунт",
                                                    ],
                                                }}
                                                img={{
                                                    src: "/main/11/tv/Yandex_1x.png",
                                                    alt: "Yandex",
                                                }}
                                            />
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION НА HISENSE" 
                                                description={{
                                                    listItem: [
                                                        "На главном экране найдите приложение KION и нажмите на иконку, подтвердите установку",
                                                        "Дождитесь установки и снова нажмите на иконку KION",
                                                        "Осталось авторизоваться в приложении под вашим номером телефона",
                                                    ],
                                                    tooltip: {value: 'Телевизоры с VIDAA U4 и более новые'},
                                                }}
                                                img={{
                                                    src: "/main/11/tv/Hisense_1x.png",
                                                    alt: "HISENSE",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Приставки */}
                            <div className={clsx(activeTabIdx !== 1 && 'pseudo-hidden')} id="content-2">
                                {/* Thumbs */}
                                <div className={clsx(s['swiper-container'], s['slider-2'], s['top-thums'], 'swiper-container', 's2')}>
                                    <div className={clsx(s['swiper-wrapper'], 'swiper-wrapper')}>
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <BoxLogoAndroid/>                           
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <BoxLogoApple/>                           
                                        </div>
                                        
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <BoxLogoApk/>                           
                                        </div>
                                    </div>
                                </div>

                                <div className={clsx(s['swiper-container'], s['slider-big-2'], 'swiper-container', 'sb2')}>
                                    <div className={clsx(s['swiper-wrapper'], 'swiper-wrapper')}>
                                        
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION на ANDROID TV" 
                                                description={{
                                                    listItem: [
                                                        'В списке приложений выберите «Google Play Store», наберите в поиске «KION» и установите приложение *',
                                                        'После установки запустите KION и войдите под своим номером',
                                                    ],
                                                    tooltip: { value: `
                                                        Версия Android на вашей приставке должна быть 7.0 и выше. <br>
                                                        Установка доступна только с территории РФ. <br>
                                                        Если у вас AOSP приставка (в ней нет Google play store), см.пункт .apk
                                                    `},
                                                }}
                                                img={{
                                                    src: "/main/11/box/AndroidTV_1x.png",
                                                    alt: "ANDROID TV",
                                                }}
                                            />
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION на APPLE TV" 
                                                description={{
                                                    listItem: [
                                                        'На главном экране нажмите на App Store, в поиске введите «KION» и нажмите на найденное приложение *',
                                                        'Нажмите кнопку загрузки, немного подождите и откройте приложение',
                                                        'Пройдите авторизацию под своим номером'
                                                    ],
                                                    tooltip: {
                                                        value: 'Доступно для Apple TV 4 поколение (2015 год) и AppleTV 4K 1 поколение (2017 год) и выше. Установка доступна только с территории РФ',
                                                        styleIcon: {position: 'relative', top: '-25%'}
                                                    },
                                                }}
                                                img={{
                                                    src: "/main/11/box/AppleTV_1x.png",
                                                    alt: "Apple TV",
                                                }}
                                            />
                                        </div>
                                        
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION на AOSP" 
                                                description={{
                                                    title: 'Для приставок без Google Play Market и поддержкой Android .apk файлов',
                                                    listItem: [
                                                        'Скачайте .apk файл по ссылке <a href="https://static.kion.ru/apk/kion_atv.apk">здесь</a>',
                                                        'Установите приложение на вашу приставку и откройте приложение',
                                                        'Пройдите авторизацию под своим номером'
                                                    ],
                                                    tooltip: {
                                                        value: 'Из-за огромного разнообразия AOSP (Android Open Source Project) приставок, стабильная работа приложения не гарантируется. Поддержка для таких приставок не оказывается. Будущие обновления необходимо ставить самостоятельно путём переустановки приложения.',
                                                        styleIcon: {position: 'relative', top: '-35%'} 
                                                    },
                                                }}
                                                img={{
                                                    src: "/main/11/box/aosp_1x.png",
                                                    alt: "apk",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* Телефоны */}
                            <div className={clsx(activeTabIdx !== 2 && 'pseudo-hidden')} id="content-3">
                                {/* Thumbs */}
                                <div className={clsx(s['swiper-container'], s['slider-3'], s['top-thums'], 'swiper-container', 's3')}>
                                    <div className={clsx(s['swiper-wrapper'], 'swiper-wrapper')}>
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <PhoneLogoAndroid/>
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide',  s['logo-slide'])}>
                                            <PhoneLogoIphone/>
                                        </div>
                                    </div>
                                </div>

                                <div className={clsx(s['swiper-container'], s['slider-big-3'], 'swiper-container', 'sb3')}>
                                    <div className={clsx(s['swiper-wrapper'], 'swiper-wrapper')}>
                                        
                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION на Android" 
                                                description={{
                                                    listItem: [
                                                        'Откройте любой из этих магазинов приложений: Google Play Market, AppBazar, Huawei AppGallery, Xiaomi GetApps, Rustore, NashStore и наберите в поиске KION',
                                                        'Нажмите кнопку установки приложения, немного подождите и откройте приложение',
                                                        'Вам осталось только авторизоваться'
                                                    ],
                                                    tooltip: {value: 'Версия Android на вашем телефоне должна быть 5.1.2. и выше'},
                                                    blocks: [
                                                        { img: '/main/11/phone/store1.svg', link: 'href://app.store' },
                                                        { img: '/main/11/phone/store2.svg', link: 'href://app.store' },
                                                        { img: '/main/11/phone/store3.svg', link: 'href://app.store' },
                                                        { img: '/main/11/phone/store4.svg', link: 'href://app.store' },
                                                        { img: '/main/11/phone/store5.svg', link: 'href://app.store' },
                                                        { img: '/main/11/phone/store6.svg', link: 'href://app.store' },
                                                    ]
                                                }}
                                                img={{
                                                    src: "/main/11/phone/android-phone.png",
                                                    alt: "Android",
                                                    style: {
                                                        scale: '1.2',
                                                        marginTop: 50,
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className={clsx(s['swiper-slide'], 'swiper-slide')}>
                                            <MainScreen11CommonDevice 
                                                title="KION на iPhone" 
                                                description={{
                                                    listItem: [
                                                        'Откройте App Store, наберите в поиске «KION» и выберите его в результатах поиска',
                                                        'Нажмите кнопку загрузки, немного подождите и откройте приложение',
                                                        'Вам осталось только авторизоваться'
                                                    ],
                                                    tooltip: {value: 'Версия iOS на вашем телефоне должна быть 14 и выше'},
                                                    blocks: [
                                                        { img: '/main/11/phone/App Store.svg', link: 'href://app.store', }
                                                    ]
                                                }}
                                                img={{
                                                    src: "/main/11/phone/iPhone.png",
                                                    alt: "iPhone",
                                                    style: {
                                                        scale: '1.2',
                                                        marginTop: 50,
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}