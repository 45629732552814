import s from './index.module.scss';

export default function OrdersBtn(props: {minMode?: boolean}) {
    return (
        <a className={s.orders} href="https://shop.mts.ru">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_714_10785)">
                <path d="M10.9 13.0001C10.9 12.503 10.4971 12.1001 9.99999 12.1001C9.50294 12.1001 9.09999 12.503 9.09999 13.0001V16.0001C9.09999 16.4972 9.50294 16.9001 9.99999 16.9001C10.4971 16.9001 10.9 16.4972 10.9 16.0001V13.0001Z" fill="#FAFAFA"/>
                <path d="M14 12.1001C14.4971 12.1001 14.9 12.503 14.9 13.0001V16.0001C14.9 16.4972 14.4971 16.9001 14 16.9001C13.5029 16.9001 13.1 16.4972 13.1 16.0001V13.0001C13.1 12.503 13.5029 12.1001 14 12.1001Z" fill="#FAFAFA"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.85755 5.51465C10.1417 5.04107 9.98813 4.42681 9.51455 4.14266C9.04097 3.85851 8.42671 4.01208 8.14256 4.48566L6.03396 8H2.99976C2.44747 8 2 8.44772 2 9C2 9.55228 2.44747 10 2.99976 10H3.07516C3.02807 10.654 2.99976 11.327 2.99976 12C2.99976 12.6995 3.03034 13.399 3.08081 14.0772C3.2755 16.6932 3.37285 18.0012 4.68572 19.314C5.9986 20.6269 7.30659 20.7243 9.92258 20.9189C10.6007 20.9694 11.3002 21 11.9998 21C12.6993 21 13.3988 20.9694 14.0769 20.9189C16.6929 20.7243 18.0009 20.6269 19.3138 19.314C20.6267 18.0012 20.724 16.6932 20.9187 14.0772C20.9692 13.399 20.9998 12.6995 20.9998 12C20.9998 11.327 20.9714 10.654 20.9244 10H20.9998C21.552 10 22 9.55228 22 9C22 8.44772 21.552 8 20.9998 8H17.9661L15.8575 4.48566C15.5734 4.01208 14.9591 3.85851 14.4856 4.14266C14.012 4.42681 13.8584 5.04107 14.1426 5.51465L15.6338 8H8.36634L9.85755 5.51465ZM5.08061 10H18.9189L18.9242 10.0713C18.9716 10.7084 18.9998 11.3578 18.9998 12C18.9998 12.6422 18.9716 13.2916 18.9242 13.9287C18.7195 16.6791 18.6058 17.1936 17.8996 17.8998C17.1934 18.606 16.6789 18.7198 13.9285 18.9245C13.2913 18.9719 12.642 19 11.9998 19C11.3575 19 10.7082 18.9719 10.071 18.9245C7.32066 18.7198 6.80614 18.606 6.09994 17.8998C5.39374 17.1936 5.27999 16.6791 5.07529 13.9287C5.02787 13.2916 4.99976 12.6422 4.99976 12C4.99976 11.3578 5.02787 10.7084 5.07529 10.0713L5.08061 10Z" fill="#FAFAFA"/>
                </g>
                <defs>
                <clipPath id="clip0_714_10785">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            {!props.minMode && (
                <span>Мои заказы</span>
            )}
        </a>
    )
}