"use client"

import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import SvgIcon from "../svg-icon";

export default function InputSearch() {
    const refInput = useRef<HTMLInputElement>(null)

    const [searchValue, setSearchValue] = useState('');
    const [clicked, setClicked] = useState(false);
    const [isDesktop, setIsDesktop] = useState(true);
    function inputVisibility() {
        return isDesktop || (!isDesktop && clicked);
    }

    useEffect(() => {
        setIsDesktop(window.innerWidth >= 1200);
        
        window.addEventListener('resize', () => {
            setIsDesktop(window.innerWidth >= 1200);
        });
    }, [])

    function onKeypressEnter(e: any) {
        if (e.key === 'Enter') search()
    }

    function search() {
        setTimeout(() => {
            if (!searchValue) return;
            window.location.href = `https://kion.ru/home/search?searchValue=${searchValue}`;
        }, 500);
    }

    function reset() {
        setSearchValue('');
        setTimeout(() => {
            refInput.current?.focus();
        });
    }

    function handleClick() {
        setClicked(true);
        setTimeout(() => {
            refInput.current?.focus();
        });
    }

    return (
        <div className={clsx('wrapper col d-flex align-items-center justify-content-end', clicked && 'full-width row h-100 align-items-center d-xl-none')}>
            {/* btn */}
            {!isDesktop && clicked && (

            <div
                className="col d-flex flex-grow-0"
                onClick={() => setClicked(false)}
            >
                <button className="bg-transparent border-0 d-flex p-0">
                    <SvgIcon className="svg-icon-chevronLeft">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                        <path
                            d="M15 5.012c0-.9-1.077-1.35-1.706-.714l-3.096 3.13C8.066 9.582 7 10.66 7 12c0 1.34 1.066 2.417 3.198 4.573l3.096 3.129c.63.637 1.706.186 1.706-.715 0-.267-.105-.524-.293-.714l-3.095-3.13c-1.106-1.118-1.79-1.815-2.223-2.389-.39-.517-.39-.697-.39-.751v-.006c0-.053 0-.234.39-.751.432-.574 1.117-1.272 2.223-2.39l3.095-3.129c.188-.19.293-.447.293-.715z" />
                        </svg>
                    </SvgIcon>
                </button>
            </div>
            )}

            <div className={clsx('search icon-left icon-right state-primary theme-header', !inputVisibility && 'd-none') }>
                <SvgIcon className="d-md-none d-xl-flex icon align-left svg-icon-search">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.702 6.804c-.547 1.01-.585 1.642-.662 2.905-.026.425-.041.859-.041 1.292 0 .433.015.867.04 1.292.078 1.263.116 1.895.663 2.905.357.662 1.439 1.743 2.1 2.1 1.01.547 1.642.585 2.906.663a21.212 21.212 0 002.583 0c1.264-.078 1.895-.116 2.905-.662.126-.068.266-.162.414-.274l3.683 3.682a1 1 0 001.414-1.414l-3.683-3.683a3.32 3.32 0 00.273-.412c.546-1.01.585-1.642.662-2.905.026-.425.041-.859.041-1.292 0-.433-.015-.867-.04-1.292-.078-1.263-.117-1.895-.663-2.905-.358-.662-1.439-1.743-2.1-2.101-1.01-.546-1.642-.585-2.906-.662A21.229 21.229 0 0011 4c-.433 0-.866.015-1.291.041-1.264.077-1.896.116-2.905.662-.662.358-1.744 1.44-2.101 2.101zm5.128-.767C10.22 6.014 10.612 6 11 6c.387 0 .78.014 1.169.037 1.241.076 1.483.105 2.076.425l.02.012c.013.009.032.02.055.037.047.032.106.076.175.132.138.113.295.254.452.41.156.157.298.314.41.453a3.026 3.026 0 01.18.25c.321.592.35.834.426 2.075.024.39.037.782.037 1.17 0 .387-.013.78-.037 1.17-.076 1.24-.105 1.483-.425 2.075l-.012.02a3.032 3.032 0 01-.17.23 5.962 5.962 0 01-.41.452 6.025 6.025 0 01-.451.41 3.124 3.124 0 01-.25.181c-.593.32-.835.35-2.076.425-.39.024-.782.038-1.17.038-.387 0-.78-.014-1.17-.038-1.24-.075-1.483-.104-2.075-.425l-.02-.011a3.125 3.125 0 01-.23-.17 6.015 6.015 0 01-.452-.41 5.977 5.977 0 01-.41-.452 3.06 3.06 0 01-.181-.25c-.32-.592-.35-.835-.425-2.075-.024-.39-.037-.783-.037-1.17 0-.388.013-.78.037-1.17.076-1.24.104-1.483.425-2.076l.012-.02.036-.055c.033-.047.077-.105.133-.174.112-.14.254-.296.41-.452.157-.157.313-.298.452-.41a3.05 3.05 0 01.23-.17l.02-.012c.592-.32.835-.349 2.076-.425z" />
                    </svg>
                </SvgIcon>

                <input
                    ref={refInput}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    autoComplete="off"
                    placeholder="Поиск"
                    type="text"
                    onKeyPress={onKeypressEnter}
                    onBlur={search}
                />

                {searchValue && (
                    <SvgIcon
                        className="icon align-right svg-icon-cross"
                        style={{visibility: 'visible'}}
                        onClick={reset}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path
                                d="M6.293 16.293a1 1 0 101.414 1.414L12 13.414l4.293 4.293a1 1 0 001.414-1.414L13.414 12l4.293-4.293a1 1 0 00-1.414-1.414L12 10.586 7.707 6.293a1 1 0 00-1.414 1.414L10.586 12l-4.293 4.293z" />
                        </svg>
                    </SvgIcon>
                )}
            </div>

            {/* <!-- MOBILE SEARCH ICON --> */}
            {/* <div
                className={clsx('col-6 d-flex justify-content-end', inputVisibility() && 'd-none' )}
                onClick={handleClick}
            >
                <button className="bg-transparent border-0 d-flex p-0">
                    <SvgIcon
                        className="svg-icon-search"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.702 6.804c-.547 1.01-.585 1.642-.662 2.905-.026.425-.041.859-.041 1.292 0 .433.015.867.04 1.292.078 1.263.116 1.895.663 2.905.357.662 1.439 1.743 2.1 2.1 1.01.547 1.642.585 2.906.663a21.212 21.212 0 002.583 0c1.264-.078 1.895-.116 2.905-.662.126-.068.266-.162.414-.274l3.683 3.682a1 1 0 001.414-1.414l-3.683-3.683a3.32 3.32 0 00.273-.412c.546-1.01.585-1.642.662-2.905.026-.425.041-.859.041-1.292 0-.433-.015-.867-.04-1.292-.078-1.263-.117-1.895-.663-2.905-.358-.662-1.439-1.743-2.1-2.101-1.01-.546-1.642-.585-2.906-.662A21.229 21.229 0 0011 4c-.433 0-.866.015-1.291.041-1.264.077-1.896.116-2.905.662-.662.358-1.744 1.44-2.101 2.101zm5.128-.767C10.22 6.014 10.612 6 11 6c.387 0 .78.014 1.169.037 1.241.076 1.483.105 2.076.425l.02.012c.013.009.032.02.055.037.047.032.106.076.175.132.138.113.295.254.452.41.156.157.298.314.41.453a3.026 3.026 0 01.18.25c.321.592.35.834.426 2.075.024.39.037.782.037 1.17 0 .387-.013.78-.037 1.17-.076 1.24-.105 1.483-.425 2.075l-.012.02a3.032 3.032 0 01-.17.23 5.962 5.962 0 01-.41.452 6.025 6.025 0 01-.451.41 3.124 3.124 0 01-.25.181c-.593.32-.835.35-2.076.425-.39.024-.782.038-1.17.038-.387 0-.78-.014-1.17-.038-1.24-.075-1.483-.104-2.075-.425l-.02-.011a3.125 3.125 0 01-.23-.17 6.015 6.015 0 01-.452-.41 5.977 5.977 0 01-.41-.452 3.06 3.06 0 01-.181-.25c-.32-.592-.35-.835-.425-2.075-.024-.39-.037-.783-.037-1.17 0-.388.013-.78.037-1.17.076-1.24.104-1.483.425-2.076l.012-.02.036-.055c.033-.047.077-.105.133-.174.112-.14.254-.296.41-.452.157-.157.313-.298.452-.41a3.05 3.05 0 01.23-.17l.02-.012c.592-.32.835-.349 2.076-.425z" />
                        </svg>
                    </SvgIcon>
                </button>
            </div> */}
        </div>
    )
}