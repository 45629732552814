import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { useStoreCtx } from "src/store";
import { getNoun } from "src/utils/string";
import { DevicePage, IDeviceOption } from "..";

export const TvPage = observer(() => {
  const httpService = useStoreCtx().appStore

  const location = useLocation()
  const selectedValue = new URLSearchParams(location.search).get('key') || ''
  const selectOptions: IDeviceOption[] = httpService.jsonTemplate.devices.tv.items.map(x => {
    return {
      value: x.keyValue, 
      label: x.keyValue + ' ' + getNoun(Number(x.keyValue), 'дюйм', 'дюйма', 'дюймов'), 
      model: x.model,
      price: x.price, 
      title: x.name, 
      gift: x.gift,
      // @ts-ignore
      specs: Object.keys(x.specifications.short).map(key => ({label: key, value: x.specifications.short[key]})), 
      buttons: x.buttons,
      images: x.images,
      // @ts-ignore
      chars: Object.keys(x.specifications.full).map(key => ({label: key, value: x.specifications.full[key]})),
    }
  })


  return (
    <DevicePage 
      breadcrumbSteps={[
        {label: 'Устройства', link: '/'},
        {label: 'Все устройства', link: '/catalog'},
        {label: 'Телевизор KION', link: ''},
      ]} 
      selectLabel={httpService.jsonTemplate.devices.tv.common.keyName}
      selectOptions={selectOptions} 
      selectedValue={selectedValue}
      stats={httpService.jsonTemplate.devices.tv.common.description}
      manuals={httpService.jsonTemplate.devices.tv.common.manuals}
      shops={httpService.jsonTemplate.devices.tv.common.shops}
    />
  );
})