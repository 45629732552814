import clsx from 'clsx';
import { MotionValue, motion, useTransform } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store';
import styles from './index.module.scss';

export const MainScreen8tv = observer((props: {
	initial: boolean, firstItem: boolean, secondItem: boolean; scrollYProgress: MotionValue<number>;
}) => {
	const store = useStore()

	const items = [
		{
			title: 'Более 200 каналов',
			description: `
				В телевизорах KION доступны разнообразные пакеты телеканалов:
				федеральные, кино, спортивные, детские, музыкальные, новостные и развлекательные
			`
		}, {
			title: 'Запись эфира 7 дней',
			description: `
				Для многих телеканалов доступна запись. Если вы пропустили любимую передачу, фильм,
				футбольный матч - их можно посмотреть в записи еще 7 дней
			`
		}
	];

	const imgPosition = useTransform(props.scrollYProgress, [0, 0.33], ['50%', '0%']);
	const imgPositionNegative = useTransform(props.scrollYProgress, [0, 0.33], ['-50%', '0%']);

	const imgScale = useTransform(props.scrollYProgress, [0, 0.33], [1.3, 1]);
	const imgScaleMob = useTransform(props.scrollYProgress, [0, 0.33], [1.6, 1]);

	// TODO брать из стора (но возникает баг с обновлением scrollYProgress)
	const isMobile = window.innerWidth < 768;

	function getAnimation(idx: number) {
		if (idx === 0) {
			if (props.initial) return 'pseudoHidden'
			if (props.firstItem) return 'visible'
			else return 'hidden'
		} else if (props.secondItem) return 'visible'
		return 'hidden'
	}

	return (
		<div className={styles.wrapper}>
			<motion.div
				className={styles.images}
				style={{
					left: isMobile ? undefined : imgPosition,
					scale: isMobile ? imgScaleMob : imgScale,
					x: isMobile ? undefined : imgPositionNegative,
					y: isMobile ? imgPositionNegative : undefined,

					textAlign: props.initial ? 'left' : 'right',
				}}
			>
				<div className={styles.frame}>
					<motion.img 
						className={clsx(!props.initial && 'invisible')}
						src={'/main/8/init.png'}
					/>
					<video 
						className={clsx(!props.firstItem && 'invisible')}
						preload="auto" 
						controls={false}
						autoPlay
						loop
						muted
						poster='/main/8/first.png'
						playsInline
					>
						<source 
							src="/main/8/tvchannels.webm" 
							type="video/webm"
						/>  
						<source 
							src="/main/8/tvchannels.mp4" 
							type="video/mp4"
						/>  
					</video>

					<video 
						className={clsx(!props.secondItem && 'invisible')}
						preload="auto" 
						controls={false}
						autoPlay
						loop
						muted
						poster='/main/8/second.png'
						playsInline
					>
						<source 
							src="/main/8/povtortv.webm" 
							type="video/webm"
						/>  
						<source 
							src="/main/8/povtortv.mp4" 
							type="video/mp4"
						/>  
					</video>
				</div>

				<div className={styles.smallDescription}>
					* количество и состав списка телеканалов может отличаться от региона просмотра
				</div>
			</motion.div>

			<div className={styles.infoWrapper}>
				{items.map((item, idx) => (
					<motion.div
						key={idx}
						className={styles.infoItem}
						animate={getAnimation(idx)}
						variants={{
							hidden: {
								y: idx === 0 ? -100 : 100,
								display: 'none',
							},
							pseudoHidden: {
								y: idx === 0 ? -100 : 100,
								visibility: 'hidden',
							},
							visible: {
								y: 0,
								visibility: 'visible',
								position: 'unset',
								zIndex: 'unset'
							},
						}}
					>
						<div className={styles.title}>
							{item.title}
						</div>
						<div className={styles.description}>
							{item.description}
						</div>
					</motion.div>
				))}
			</div>
		</div>
	);
})
