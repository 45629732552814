import { motion } from 'framer-motion';
import styles from './inches.module.scss';
import clsx from 'clsx';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store';
import { Link, useNavigate } from 'react-router-dom';

export const MainFirstCharacteristics = observer((props: { animate: boolean; duration: number; }) => {
	const store = useStore()
	const navigate = useNavigate()

	const [activeIdx, setActiveIdx] = useState(1);

	const characters = store.jsonTemplate.devices.tv.items.map((x, idx) => ({
		keyValue: x.keyValue,
		coast: x.price,
		width: 76 + (idx * 2),
		height: 43 + (idx * 3),
	}));

	const handleActiveIdxChange = (newIdx: number) => {
		setActiveIdx(newIdx);
		// navigate("/catalog/tv" + `?key=${characters[newIdx].keyValue}`);
		navigate("/catalog");
	}

	return (
		<motion.div
			className={styles.info}
			animate={props.animate ? 'anim' : 'init'}
			variants={{
				init: {
					y: 100,
					opacity: 0,
					scaleX: 0.5,
				},
				anim: {
					opacity: 1,
					y: 230,
					scale: 1,
					transition: {
						delay: 0.05,
						duration: props.duration,
					}
				}
			}}
		>
			<motion.img style={{ visibility: store.isMobile ? 'hidden' : 'visible' }} src="/main/1/title.svg" />

			<div className={styles.characters}>
				{characters.map((item, idx) => (
					<div
						key={idx}
						className={clsx(styles.char, 'clickable', activeIdx === idx && styles.charActive)}
						style={{
							minHeight: item.height + 'px',
							maxHeight: item.height + 'px',
							minWidth: item.width + 'px',
						}}
						onClick={() => handleActiveIdxChange(idx)}
					>
						<div className={styles.inch}>
							{item.keyValue}&ldquo;
						</div>
						<div className={styles.coast}>
							от {item.coast} ₽
						</div>
					</div>
				))}
			</div>

			<Link to={"/catalog"}>
				<button className={clsx(styles.button, 'clickable')}>
						УЗНАТЬ БОЛЬШЕ
				</button>
			</Link>
		</motion.div >
	);
})
