import { makeAutoObservable } from "mobx"
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { WebSsoJWT } from "src/utils/HashParser";
import response from '../assets/template.json'

interface AuthResponse {
  userId: string
  accessToken: string
  refreshToken: string
  login: string
  password: string
  tokenType: string
  firstLogin: boolean
  expiresIn: number
}

export interface MtsBankWidgetResponse {
  isSuccess: boolean
  errorCode?: string
  message?: string
  eventName: 'successPayment' | 'failPayment' | 'validationError'
}

export interface KionPostersResponse {
  title: string,
  items: KionPostersItemResponse[]
}

export interface KionPostersItemResponse {
  title: string,
  imageUrl: string
}

export interface MtsBankWidgetConfig {
  sessionId: string,
  scenarioType: 'pay',
  tokenId: string,
  environment: 'PROD',
  resultHandler: (resp: MtsBankWidgetResponse) => void
}

export class HttpService {
  constructor() {
    makeAutoObservable(this)
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768
    })
  }

  jsonTemplate = response

  isMobile: boolean = window.innerWidth < 768

  get grclientid(): string | null {
    return this._grclientid
  }

  set grclientid(value: string | null) {
    this._grclientid = value
  }

  get userId(): string | null {
    return this._userId;
  }

  set userId(value: string | null) {
    this._userId = value;
  }

  get userAvatar(): string | null {
    return this.webSSOJwt?.picture || null;
  }

  get webSSOJwt(): WebSsoJWT | {[key: string]: string} | null {
    return this._webSSOJwt;
  }

  set webSSOJwt(value: WebSsoJWT | {[key: string]: string} | null) {
    this._webSSOJwt = value;
  }

  private token: null | string = null
  private _jwtToken = ''
  private _webSSOJwt: null | WebSsoJWT | {[key: string]: string} = null
  private _phone = ''
  private _userId: null | string = null
  private _grclientid: null | string = null
  private _clientTerminalId = ''

  private baseURL = '/api'

  private post<T>(
    endpoint: string,
    payload: { [key: string]: unknown } | string = {},
    externalOptions = {}
  ): Promise<AxiosResponse<T>> {
    let options: AxiosRequestConfig = {
      ...externalOptions
      // withCredentials: true
    }

    if (this.token) {
      options = {
        ...options,
        headers: {
          'Authorization': `Bearer ${ this.token }`
        }
      }
    }

    return axios.post<T>(this.baseURL + endpoint, payload, options)
  }

  public auth(nonce: string, webssoCode: string): Promise<AxiosResponse<AuthResponse>> {
    const clientTerminalId = this.clientTerminalId = this.phone + '-' + uuidv4()
    return this.post<AuthResponse>(
      '/ott/actions/websso-login',
      {
        clientTerminalId,
        terminalType: '2',
        deviceModel: 'Landing',
        deviceName: 'welcome_kion_ru',
        deviceOS: 'Landing KION',
        refreshTokenSupported: false,
        webssoCode,
        nonce
      }
    )
  }

  public getUserId() {
    const options: AxiosRequestConfig = {
      baseURL: '/coreapi',
      headers: {
        'Authorization': `Bearer ${ this.token }`
      }
    }
    return axios.get<{huaweiSubscriberId: string}>('/subscribers/me',  options)
  }

  public getImgsForSwapper() {
    const config: AxiosRequestConfig = {
      baseURL: '/shelvesapi',
      headers: {
        'X-Device-Model': 'AndroidPhone_Widevine_VMX_v3',
        'X-Device-Id': 'devices_kion_ru',
        'X-App-Version': '1'
      }
    }

    return axios.get<KionPostersResponse>('/shelves/glo_shelf_blender_1141', config)
  }

  get phone() {
    return this._phone
  }

  set phone(phone: string) {
    this._phone = phone
  }

  get jwtToken() {
    return this._jwtToken
  }

  set jwtToken(str: string) {
    this._jwtToken = str
  }

  get clientTerminalId(): string {
    return this._clientTerminalId
  }

  set clientTerminalId(value: string) {
    this._clientTerminalId = value
  }

  public set accessToken(str: string) {
    this.token = str
  }

}