import {MainPostersLine} from 'src/components/screens/3/3_poster-line/poster-line';
import clsx from 'clsx';
import { MotionValue, motion, useScroll, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import styles from './screen.module.scss';
import MainScreen3Image from 'src/components/screens/3/3_images';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store';

function getScale(y: number, sizes: { min: number, max: number; }, yMin: number, yMax: number): number {
	if (y < yMin) return sizes.min;
	if (y > yMax) return Math.max(sizes.max - (y - yMax), sizes.min);
	return Math.min(sizes.min + y - yMin, sizes.max);
}

function getOpacity(y: number, sizes: { min: number, max: number; }, yMin: number): number {
	if (y < yMin) return sizes.min;
	return Math.min(sizes.min + y - yMin, sizes.max);
}

export const MainScreen3 = observer((props: {
	opacity: MotionValue<number>;
}) => {
	const store = useStore()

	const refContainer = useRef(null);
	const { scrollYProgress } = useScroll({
		target: refContainer
	});

	const wrapperPosition = useTransform(scrollYProgress, (y) => {
		if (y < 0.33) return '10%';
		if (y < 0.66) return '0%';
		return '-15%';
	});

	const gap = useTransform(scrollYProgress, (y) => {
		if (y < 0.33) return store.isMobile ? '70px' : '100px';
		if (y < 0.66) return store.isMobile ? '40px' : '56px';
		return '28px';
	});

	const scaleHeading = useTransform(scrollYProgress, (y) => getScale(y, { min: 0.7, max: 1 }, 0, 0.25));
	const opacityHeading = useTransform(scrollYProgress, (y) => getOpacity(y, { min: 0.7, max: 1 }, 0));

	const scaleImage = useTransform(scrollYProgress, (y) => getScale(y, { min: 0.9, max: 1.1 }, 0.25, 0.50));
	const opacityImage = useTransform(scrollYProgress, (y) => getOpacity(y, { min: 0.9, max: 1.1 }, 0.25));

	const scaleDescription = useTransform(scrollYProgress, (y) => getScale(y, { min: 0.7, max: 1 }, 0.50, 0.75));
	const opacityDescription = useTransform(scrollYProgress, (y) => getOpacity(y, { min: 0.7, max: 1 }, 0.50));

	const scalePosterLine = useTransform(scrollYProgress, (y) => getScale(y, { min: 0.8, max: 1 }, 0.75, 1));
	const opacityPosterLine = useTransform(scrollYProgress, (y) => getOpacity(y, { min: 0.7, max: 1 }, 0.75));

	const _inlineTransform = useTransform(scrollYProgress, (y) => y > 0.75)
	const [inline, setInline] = useState<boolean>(_inlineTransform.get())
	useEffect(() => _inlineTransform.on('change', setInline), [])

	return (
		<div ref={refContainer} className={clsx(styles.third)}>
			<motion.div
				className={clsx(styles.info, 'sticky-view-sticky')}
				style={{
					gap: gap,
					opacity: props.opacity,
				}}
			>
				<motion.div
					className={styles.title}
					style={{
						scale: scaleHeading,
						opacity: opacityHeading,
						marginTop: wrapperPosition,
					}}
				>
					Весь
				</motion.div>

				<MainScreen3Image scale={scaleImage} opacity={opacityImage} inline={inline} />

				<motion.div
					className={styles.subtitle}
					style={{
						scale: scaleDescription,
						opacity: opacityDescription,
					}}
				>
					В вашем телевизоре
				</motion.div>

				{/* <MainPostersLine
					y={scrollYProgress}
					scale={scalePosterLine}
					opacity={opacityPosterLine}
				/> */}
			</motion.div>
		</div >
	);
})
