import MainFirstTvBlockPoster from 'src/components/screens/1/1_posters/poster';
import { MotionValue } from 'framer-motion';
import s from './index.module.scss';

interface IAxis {
	x: number
	y: number
	rotate?: number
}

export interface IPosterProps {
	img: string
	posStart: IAxis
	posEnd: IAxis
}

export default function MainFirstTvBlockPosters(props: {
	positionY: MotionValue<number>;
}) {

	const posters: IPosterProps[] = [
		{
			img: '/main/1/posters/dedline.png',
			posStart: {
				x: -30,
				y: -30,
			},
			posEnd: {
				x: -500,
				y: -200,
				rotate: 90,
			},
		},
		{
			img: '/main/1/posters/image 49.png',
			posStart: {
				x: 30,
				y: 30,
			},
			posEnd: {
				x: 0,
				y: -400,
				rotate: 60,
			}
		},
		{
			img: '/main/1/posters/image 11.png',
			posStart: {
				x: 140,
				y: 60,
			},
			posEnd: {
				x: 130,
				y: 300,
				rotate: 0,
			}
		},
		{
			img: '/main/1/posters/image 50.png',
			posStart: {
				x: 245,
				y: 40,
			},
			posEnd: {
				x: 630,
				y: -130,
				rotate: 90,
			}
		},
		{
			img: '/main/1/posters/image 52.png',
			posStart: {
				x: 385,
				y: -40,
			},
			posEnd: {
				x: 680,
				y: -230,
				rotate: 90,
			}
		},
		{
			img: '/main/1/posters/image 51.png',
			posStart: {
				x: 345,
				y: 30,
			},
			posEnd: {
				x: 880,
				y: -140,
				rotate: 90,
			}
		},
	];

	return (
		<div className={s.wrapper}>
			{posters.map((item, idx) => (
				<MainFirstTvBlockPoster key={idx} item={item} positionY={props.positionY} />
			))}
		</div>
	);
}
