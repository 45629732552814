import { Link } from 'react-router-dom';
import s from './index.module.scss';

export default function TvTab3(props: { manuals:  {title: string, items: {url: string, text: string}[]}[] }) {

  return (
    <div className={s.wrapper}>
      {props.manuals.map(manBlock => (
        <>
          <h2>{manBlock.title}</h2>
          <div className={s.instrWrapper}>
            {manBlock.items.map((manItem) => (
              <p>
                <a href={manItem.url} target="_blank"
                  className={s['instruction-link']}
                >
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="20.001"
                        viewBox="0 0 16 20.001"
                    >
                      <path d="M16.491,7.449c.009.134.009.3.009.5V10c0,1.55-.068,2.9-.168,4.055-.176,2.024-.264,3.036-1.557,4.326s-2.264,1.37-4.2,1.532C9.921,19.968,9.227,20,8.5,20s-1.421-.033-2.07-.087c-1.941-.162-2.911-.243-4.2-1.532S.843,16.08.668,14.056C.568,12.9.5,11.55.5,10S.567,7.1.668,5.946C.843,3.921.931,2.908,2.225,1.619S4.49.249,6.432.087C7.08.033,7.773,0,8.5,0h.012a2.868,2.868,0,0,1,1.579.295,23.085,23.085,0,0,1,3.2,2.894,31.581,31.581,0,0,1,2.8,3.18c.076.1.135.174.178.228A1.5,1.5,0,0,1,16.491,7.449ZM10.5,15a1,1,0,0,0-1-1h-4a1,1,0,0,0,0,2h4A1,1,0,0,0,10.5,15Zm-3-5a1,1,0,0,1,0,2h-2a1,1,0,0,1,0-2ZM9.857,2.661c-.139-.125-.257-.228-.356-.312q0,.075,0,.151a17.359,17.359,0,0,0,.263,2.825c.093.548.14.822.365,1.047s.5.272,1.047.365A17.358,17.358,0,0,0,14,7h.042a30.16,30.16,0,0,0-2.169-2.4C10.984,3.713,10.333,3.09,9.857,2.661Z"
                      transform="translate(-0.5 0)"
                      fill="#45b7fc"
                      fillRule="evenodd" />
                    </svg>
                  </span>
                  {manItem.text}
                </a>
              </p>
            ))}
          </div>
        </>
      ))}

      <p>Только последняя версия документа доступна для скачивания. Возможны отличия от скачиваемого файла и печатной версии, который идёт с товаром.</p>

      <p className={s.grey}>
        Информация о технических характеристиках, комплекте поставки, стране изготовления и внешнем виде товара носит справочный характер. Точную информацию уточняйте у продавца или в сервисных центрах поддержки.
      </p>
      <Link className={s.service} to="/catalog/service-center">Адреса сервисных центров и поддержки</Link>
    </div>
  );
}