import { motion, useScroll, useTransform } from 'framer-motion';
import styles from './screen.module.scss';
import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store';

export const MainScreen7 = observer(() => {
	const store = useStore()

	const refContainer = useRef(null);
	const { scrollYProgress } = useScroll({
		target: refContainer
	});

	return (
		<div ref={refContainer} className={styles.seven}>
			<motion.img
				className='sticky-view-sticky'
				src={store.isMobile ? "/main/seven/Card-mob.svg" : "/main/seven/Card.svg"}
			/>
		</div>
	);
})
