import { CSSProperties, PropsWithChildren } from 'react';
import s from './index.module.scss';

export default function Button(props: { 
  url?: string, 
  textColor?: string, 
  backgroundColor?: string, 
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  style?: CSSProperties
} & PropsWithChildren) {
  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.stopPropagation()
    if (props.url) window.location.assign(props.url)
    props.onClick?.(e);
  }

  return (
    <a 
        className={s['buy-btn']} 
        style={{ ...props.style, color: props.textColor, background: props.backgroundColor }}
        href={props.url} 
        target="_blank"
        onClick={onClick}
    >
        {props.children}
    </a>
  )
}
