import s from './index.module.scss';

export default function TvTab2(props: {chars: {label: string, value: string}[]}) {
  return (
    <div className={s.wrapper}>
      <h2>Характеристики</h2>

      <div className={s.flexis}>
        {props.chars.map((item, idx) => (
          <div className={s.line} key={idx}>
            <div>{ item.label }</div>
            <div className={s.tr}>
              { item.value }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}