import styles from './footer.module.scss'

export default function FooterComponent() {
  const currentYear = new Date().getFullYear()

  return (
    <footer className={styles.footer}>
      <p>© { currentYear } ПАО «МТС». Все права защищены. 12+</p>
    </footer>
  )
}