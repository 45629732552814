import { MainFirstCharacteristics } from 'src/components/screens/1/1_characters/inches';
import MainFirstTvBlockPosters from 'src/components/screens/1/1_posters';
import clsx from 'clsx';
import { MotionValue, motion, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';

export default function MainFirstTvBlock(props: {
	initAnimate: boolean, secondAnimate: boolean; positionY: MotionValue<number>;
}) {
	const secondAnimateDuration = 0.25;
	const headerY = useTransform(props.positionY, [0, 0.5, 0.8, 0.9, 1], [0, -500, -500, -550, -650]);
	const scale = useTransform(props.positionY, [0, 0.33, 0.66], [1, 0.7, 0.7]);

	const [isVideoVisible, setIsVideoVisible] = useState(false);
	useEffect(() => {
		props.positionY.on('change', (y) => {
			if (!refVideo.current) return;

			refVideo.current.defaultMuted = true
			refVideo.current.muted = true
			refVideo.current.setAttribute('muted', '')

			if (y > 0.2) {
				setIsVideoVisible(true)
				refVideo.current.play()
			} else {
				setIsVideoVisible(false)
				refVideo.current.pause()
				refVideo.current.currentTime = 0
			}
		})
	}, [])

	const refVideo = useRef<HTMLVideoElement>(null)
	const refIsMuted = useRef<boolean>(true)

	return (
		<motion.div className={style.tvBlock}
			style={{
				y: headerY,
				scale: scale,
			}}
			initial={{ y: 100 }}
		>
			<motion.div
				className={clsx(style.tv, style.glow, props.secondAnimate ? style.glowLight : style.glowRed)}
			>
				<motion.img src='/main/1/tv-frame.png' />

				<video 
					ref={refVideo}
					className={clsx(!isVideoVisible && 'pseudo-hidden')}
					preload="auto" 
					controls={false}
					autoPlay={isVideoVisible}
					loop
					muted={refIsMuted.current}
					playsInline
				>
					<source 
						src="/main/1/video_in_tv.webm" 
						type="video/webm"
					/>  
					<source 
						src="/main/1/video_in_tv.mp4" 
						type="video/mp4"
					/>  
				</video>

				<MainFirstCharacteristics
					animate={props.secondAnimate}
					duration={secondAnimateDuration}
				/>
			</motion.div>

			<MainFirstTvBlockPosters positionY={props.positionY} />
		</motion.div>
	);
}
