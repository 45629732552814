import React from "react";
import { Link } from "react-router-dom";
import s from './index.module.scss';

export default function Breadcrumbs(props: {steps: {label: string, link: string}[]}) {
  const firstItems = props.steps.slice(0, props.steps.length - 1)
  const lastItem = props.steps[props.steps.length-1]
  return (
    <div className={s.breadcrumb}>
      {firstItems.map((item, idx) => (
        <React.Fragment key={idx}>
          <Link to={item.link}>{item.label}</Link>
          <span className={s.arrow}>
              <svg width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"><path d="M0.164063 0.751965C0.164062 0.0833753 0.97192 -0.251454 1.44439 0.22131L3.20307 1.98106C4.62529 3.40416 5.3364 4.11571 5.3364 4.9999C5.3364 5.8841 4.62529 6.59565 3.20306 8.01874L1.44439 9.77849C0.97192 10.2513 0.164063 9.91643 0.164063 9.24784C0.164062 9.0488 0.24308 8.85792 0.383733 8.71718L2.1424 6.95743C2.8835 6.21588 3.33037 5.76423 3.61053 5.39681C3.73984 5.22723 3.79494 5.12363 3.81862 5.0668C3.83652 5.02386 3.83644 5.0087 3.8364 5.00091L3.8364 4.9999L3.8364 4.9989C3.83644 4.99111 3.83652 4.97594 3.81862 4.93301C3.79494 4.87617 3.73984 4.77258 3.61053 4.603C3.33037 4.23558 2.8835 3.78393 2.14241 3.04237L0.383732 1.28262C0.24308 1.14188 0.164063 0.950999 0.164063 0.751965Z"
                                                            fill="#677F99" />
              </svg>
            </span>
        </React.Fragment>
      ))}
      <span className={s.active}>{ lastItem.label }</span>
    </div>
  )
}