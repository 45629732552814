import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";
import Swiper, { Thumbs } from "swiper";
import s from './index.module.scss';

export default function InfoBlock(props: {
      tab1: ReactNode
      tab2: ReactNode
      tab3: ReactNode
      tab4: ReactNode
}) {
      const [activeTabIdx, setActiveTabIdx] = useState(0);

      useEffect(() => {
            const sliderBigTabs = new Swiper('.s1', {
                  slidesPerView: 'auto',
                  watchSlidesProgress: true,
            });

            new Swiper(".sb1", {
                  thumbs: {
                        swiper: sliderBigTabs,
                  },
                  autoHeight: true, 
                  slidesPerView: 'auto',
                  modules: [Thumbs]
            });
      }, []);

      return (
            <div className={s.tabss}>
                  {/* thumbsslider */}
                  <div className={clsx('swiper-container', s['swiper-container'], s['slider-big-tabs'], s['tabs-1'], 's1')}>
                        <div className={clsx(s.labels, 'swiper-wrapper')}>
                              <div 
                                    className={clsx(s['tab-label'], s['tab-label-1'], 'swiper-slide', s['swiper-slide'], activeTabIdx === 0 && s['tab-active'])} 
                                    onClick={() => setActiveTabIdx(0)}
                              >
                                    Описание
                              </div>
                              <div 
                                    className={clsx(s['tab-label'], s['tab-label-2'], 'swiper-slide', s['swiper-slide'], activeTabIdx === 1 && s['tab-active'])} 
                                    onClick={() => setActiveTabIdx(1)}
                              >
                                    Характеристики
                              </div>
                              <div 
                                    className={clsx(s['tab-label'], s['tab-label-3'], 'swiper-slide', s['swiper-slide'], activeTabIdx === 2 && s['tab-active'])} 
                                    onClick={() => setActiveTabIdx(2)}
                              >
                                    Инструкции и документы
                              </div>
                              <div 
                                    className={clsx(s['tab-label'], s['tab-label-4'],  'swiper-slide', s['swiper-slide'], activeTabIdx === 3 && s['tab-active'])} 
                                    onClick={() => setActiveTabIdx(3)}
                              >
                                    Где купить?
                              </div>
                        </div>
                  </div>

                  <div className={clsx('swiper-container', s['swiper-container'], s['slider-tabs'], 'sb1')}>
                        <div className="swiper-wrapper">
                              <div className={clsx('swiper-slide', s['swiper-slide'])}>
                                    {props.tab1}
                              </div>
                              <div className={clsx('swiper-slide', s['swiper-slide'])}>
                                    {props.tab2}
                              </div>
                              <div className={clsx('swiper-slide', s['swiper-slide'])}>
                                    {props.tab3}
                              </div>
                              <div className={clsx('swiper-slide', s['swiper-slide'])}>
                                    {props.tab4}
                              </div>
                        </div>
                  </div>
            </div>
      )
}