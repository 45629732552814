import { parseJWT, parseUriHash } from 'src/utils/HashParser'
import { useEffect } from 'react'
import s from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { createPortal } from 'react-dom'
import { useStoreCtx } from 'src/store'
import { useLocation, useNavigate } from 'react-router-dom';

export const SsoAuth = observer(() => {
    const httpService = useStoreCtx().appStore

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        auth()
    }, [])

    async function auth() {
        const hash = location.hash
        
        if (Object.keys(location.search).length === 0 && !hash) {
            navigate('/')
        }
        const { code, id_token } = parseUriHash(hash)
        httpService.jwtToken = id_token
        const { nonce, sub } = httpService.webSSOJwt = parseJWT(id_token)
        try {
            console.log('try to auth');
            const { accessToken } = (await httpService.auth(nonce, code)).data
            console.log('access', accessToken);

            httpService.accessToken = accessToken
            httpService.userId = sub
            navigate('/')
        } catch (e) {
            console.log('err', e);
            // TODO
            // navigate('/error')
            navigate('/')
        }
    }

    return (
        createPortal(
            <div className={s.overlay}>
                <div className={s.modal}>
                    <div className={s.modal__content}>
                    <div className={s.wrapper}>
                        Авторизация...
                    </div>
                </div>
            </div>
            </div>, 
            document.body
        )
    )
})