import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Button from "src/pages/device-page/components/button";
import { DeviceType, ISelectedFilters } from "..";
import OrdersBtn from "../../device-page/components/orders-btn/orders-btn";
import s from './index.module.scss';

export const FiltersCatalog = observer((props: {
    itemsFull: any
    currentType: DeviceType
    onChangeCurrentType: (val: DeviceType) => void,
    selectedFilters: ISelectedFilters
    onChangeSelectedFilters: (val: ISelectedFilters) => void,
    mobileShown: boolean,
    onChangeMobileShown: (val: boolean) => void,
    filteredItemsCount: number,
}) => {
    const [moreFilters, setMoreFilters] = useState(false);
    const filters = props.itemsFull.map((el: any) => el.specifications.full).reduce((acc: any, item: any) => {
        Object.keys(item).forEach(key => {
            acc[key] = Array.from(acc[key] || [])
            acc[key].push(item[key])
            acc[key] = new Set(acc[key]);
        })
        return acc;
    }, {});

    const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters>({});

    const deviceType: {title: string, type: DeviceType}[] = [
        {title: 'Телевизор', type: 'tv'},
        {title: 'Приставка', type: 'smartbox'},
    ]

    function selectFilter(key: string, value: string) {
        const newSet = new Set(selectedFilters?.[key] || [])
        
        if (selectedFilters?.[key]?.has(value)) {
            newSet.delete(value)
            const updatedObj = {...selectedFilters, [key]: newSet}
            if (selectedFilters?.[key].size === 1) delete updatedObj[key]
            setSelectedFilters(updatedObj)
            props.onChangeSelectedFilters(updatedObj)
        } else {
            const updatedObj = {...selectedFilters, [key]: newSet.add(value)}
            setSelectedFilters(updatedObj)
            props.onChangeSelectedFilters(updatedObj)
        }
    }

    const [mobileFiltersShown, setMobileFiltersShown] = useState(props.mobileShown)
    useEffect(() => {
        setMobileFiltersShown(props.mobileShown)
    }, [props.mobileShown])

    useEffect(() => {
        props.onChangeMobileShown(mobileFiltersShown)
    }, [mobileFiltersShown])

    function changeDeviceType(type: DeviceType) {
        setSelectedFilters({})
        props.onChangeSelectedFilters({})
        props.onChangeCurrentType(type)
    }

    function resetFilters() {
        setSelectedFilters({})
        props.onChangeSelectedFilters({})
    }

    return (
        <>
            <div className={s.selectedFilters}>
                <div>{props.currentType === 'tv' ? 'Телевизор' : 'Приставка'}</div>

                {Object.keys(selectedFilters).map(key => Array.from(selectedFilters[key]).map(x => (
                        <div className="clickable" onClick={() => selectFilter(key, x)}>
                            <div>{x}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M10.7206 11.7801C11.0133 12.0728 11.4878 12.0728 11.7805 11.7801C12.0731 11.4875 12.0731 11.013 11.7805 10.7203L9.06002 8.00002L11.7805 5.27966C12.0732 4.987 12.0732 4.51252 11.7805 4.21987C11.4878 3.92721 11.0133 3.92721 10.7207 4.21987L8.00019 6.94023L5.27934 4.21949C4.98667 3.92684 4.51217 3.92684 4.2195 4.21949C3.92683 4.51214 3.92683 4.98663 4.2195 5.27928L6.94035 8.00002L4.21954 10.7207C3.92687 11.0134 3.92687 11.4879 4.21954 11.7805C4.5122 12.0732 4.98671 12.0732 5.27937 11.7805L8.00019 9.05981L10.7206 11.7801Z" fill="white"/>
                            </svg>
                        </div>
                    ))
                )}
            </div>

        <div className={clsx(s.filtersItems, mobileFiltersShown && s.filtersItemsMobileShown)}>
            <div className={clsx(s.staticItem, s.item)}>
                <div className={clsx(s.label, s.grey, s.small)}>Заказы</div>
                <OrdersBtn/>
            </div>

            <div className={clsx(s.filtersWrapper)}>
                <div className={s.cross} onClick={() => setMobileFiltersShown(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6.29289 16.2929C5.90237 16.6834 5.90237 17.3166 6.29289 17.7071C6.68342 18.0976 7.31658 18.0976 7.70711 17.7071L11.9999 13.4143L16.2929 17.7073C16.6834 18.0978 17.3166 18.0978 17.7071 17.7073C18.0976 17.3167 18.0976 16.6836 17.7071 16.293L13.4141 12.0001L17.7071 7.70711C18.0976 7.31658 18.0976 6.68342 17.7071 6.29289C17.3166 5.90237 16.6834 5.90237 16.2929 6.29289L11.9999 10.5859L7.70711 6.29304C7.31658 5.90252 6.68342 5.90252 6.2929 6.29304C5.90237 6.68357 5.90237 7.31673 6.29289 7.70726L10.5857 12.0001L6.29289 16.2929Z" fill="white"/>
                    </svg>
                </div>

                <div className={clsx(s.mainLabel, s.label, s.grey, s.small)}>Фильтры</div>

                <div className={s.filters}>
                    <div className={s.filtersItem}>
                        <div className={clsx(s.label)}>Тип устройства</div>
                        <div className={s.radio}>
                            {deviceType.map(type => (
                                <div 
                                    className={clsx(s.label, s.small, 'clickable')}
                                    onClick={() => changeDeviceType(type.type)}
                                >
                                    {type.type === props.currentType ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" fill="#45B7FC"/>
                                            <path d="M6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12Z" fill="#45B7FC"/>
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" fill="#BCC3D0" fill-opacity="0.3"/>
                                        </svg>
                                    )}
                                    {type.title}
                                </div>
                            ))}
                        </div>
                    </div>

                    {Object.keys(filters).slice(0, (moreFilters || mobileFiltersShown) ? undefined : 2).map((key: any) => (
                        <div key={key} className={s.filtersItem}>
                            <div className={s.label}>{key}</div>
                            <div className={clsx(s.checkbox, 'clickable')}>
                                {Array.from(filters[key]).map((item: any) => (
                                    <div className={clsx(s.label, s.small)} onClick={() => selectFilter(key, item)}>
                                        {selectedFilters?.[key]?.has(item) ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.68585 4.68597C3.37297 5.99884 3.27563 7.30684 3.08093 9.92282C3.03046 10.601 2.99988 11.3005 2.99988 12C2.99988 12.6995 3.03046 13.399 3.08093 14.0772C3.27563 16.6932 3.37297 18.0012 4.68585 19.314C5.99872 20.6269 7.30671 20.7243 9.9227 20.9189C10.6008 20.9694 11.3004 21 11.9999 21C12.6994 21 13.3989 20.9694 14.0771 20.9189C16.693 20.7243 18.001 20.6269 19.3139 19.314C20.6268 18.0012 20.7241 16.6932 20.9188 14.0772C20.9693 13.399 20.9999 12.6995 20.9999 12C20.9999 11.3005 20.9693 10.601 20.9188 9.92282C20.7241 7.30684 20.6268 5.99884 19.3139 4.68597C18.001 3.3731 16.693 3.27575 14.0771 3.08105C13.3989 3.03058 12.6994 3 11.9999 3C11.3004 3 10.6008 3.03058 9.9227 3.08105C7.30671 3.27575 5.99872 3.3731 4.68585 4.68597ZM16.2069 10.2071C16.5974 9.81658 16.5974 9.18342 16.2069 8.79289C15.8164 8.40237 15.1832 8.40237 14.7927 8.79289L10.9998 12.5858L9.70688 11.2929C9.31635 10.9024 8.68319 10.9024 8.29266 11.2929C7.90214 11.6834 7.90214 12.3166 8.29266 12.7071L10.2927 14.7071C10.6832 15.0976 11.3164 15.0976 11.7069 14.7071L16.2069 10.2071Z" fill="#45B7FC"/>
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M4.07829 9.99704C4.27799 7.31387 4.38354 6.40261 5.39308 5.39308C6.40261 4.38354 7.31387 4.27799 9.99704 4.0783C10.6547 4.02935 11.3291 4 12 4C12.6709 4 13.3453 4.02935 14.003 4.07829C16.6861 4.27799 17.5974 4.38354 18.6069 5.39308C19.6165 6.40261 19.722 7.31387 19.9217 9.99704C19.9707 10.6547 20 11.3291 20 12C20 12.6709 19.9707 13.3453 19.9217 14.003C19.722 16.6861 19.6165 17.5974 18.6069 18.6069C17.5974 19.6165 16.6861 19.722 14.003 19.9217C13.3453 19.9707 12.6709 20 12 20C11.3291 20 10.6547 19.9707 9.99704 19.9217C7.31387 19.722 6.40261 19.6165 5.39308 18.6069C4.38354 17.5974 4.27799 16.6861 4.0783 14.003C4.02935 13.3453 4 12.6709 4 12C4 11.3291 4.02935 10.6547 4.07829 9.99704Z" stroke="#BCC3D0" stroke-opacity="0.3" stroke-width="2"/>
                                            </svg>
                                        )}
                                        <div>{item}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}

                    {!mobileFiltersShown && Object.entries(filters).length > 2 && (
                        <div className={clsx(s.textBtn, 'clickable')} onClick={() => setMoreFilters(!moreFilters)}>
                            {moreFilters ? 'Меньше фильтров' : 'Больше фильтров'}
                        </div>
                    )}

                    {!!Object.keys(selectedFilters).length && (
                        <div className={clsx(s.textBtn, 'clickable')} onClick={() => resetFilters()}>
                            Сбросить все
                        </div>
                    )}
                </div>
            </div>

            <div className={s.mobileButtons}>
                <Button onClick={() => props.onChangeMobileShown(false)}>Показать ({props.filteredItemsCount})</Button>
                <Button 
                    onClick={() => {
                        resetFilters()
                        props.onChangeMobileShown(false)
                    }}
                    textColor="#677F99"
                    backgroundColor="rgba(188, 195, 208, 0.50)"
                >
                    Сбросить
                </Button>
            </div>
        </div>
        </>
    );
})