import clsx from 'clsx';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import style from './screen.module.scss';
import MainFirstTvBlock from 'src/components/screens/1/1_tv-block';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store';
import { supportsPassiveEvents } from 'detect-passive-events';

export const MainScreen1 = observer((props: { bgVisible: boolean; }) => {
	const store = useStore();

	// Нахождение в фазе начальной анимации
	const [initAnimPhase, setInitAnimPhase] = useState(false);

	// Нахождение в фазе второй анимации
	const [secondAnimPhase, setSecondAnimPhase] = useState(false);

	// Граница окончания начальной анимации
	const initAnimEndPosY = 0.5;
	
	//
	
	// Ссылка на контейнер, используемый для прокрутки
	const refContainer = useRef<HTMLDivElement>(null);
	
	// Прогресс прокрутки
	const { scrollYProgress } = useScroll({
		target: refContainer,
	});

	// Текущая позиция (для отслеживания момента изменения позиции)
	const [posY, setPosY] = useState(0);

	//

	// // Нахождние в процессе автоматиеческой прокрутки
	// const [isAutoScrolling, setIsAutoScrolling] = useState(false);

	// // 

	// const wheelOption = supportsPassiveEvents ? { passive: false } : false;
	// const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

	// // Отключить скролл
	// function disableScroll() {
	// 	window.addEventListener('DOMMouseScroll', e => e.preventDefault(), false); // Для старых FireFox
	// 	window.addEventListener(wheelEvent, e => e.preventDefault(), wheelOption); // Современный веб (десктоп)
	// 	window.addEventListener('touchmove', e => e.preventDefault(), wheelOption); // Мобила
	// }

	// // Включить скролл
	// function enableScroll() {
	// 	window.removeEventListener('DOMMouseScroll', e => e.preventDefault(), false);
	// 	// @ts-ignore // TODO: Что тут не так?
	// 	window.removeEventListener(wheelEvent, e => e.preventDefault(), wheelOption);
	// 	// @ts-ignore
	// 	window.removeEventListener('touchmove', e => e.preventDefault(), wheelOption);
	// }

	// // Автоматический плавный скролл до позиции
	// function smoothlyScrollTo(posY: number) {
	// 	// 
	// 	// disableScroll(); // TODO: Оно блокируется, но не разблокируется...
	// 	scrollYProgress.stop();
	// 	setIsAutoScrolling(true);

	// 	// Плавно прокручиваем
	// 	window.scrollTo({ top: posY, behavior: 'smooth' });

	// 	return new Promise<void>((resolve, reject) => {
	// 		const failed = setTimeout(() => {
	// 			reject();
	// 		}, 10000);

	// 		const resolvePromise = () => {
	// 			// Включаем скролл обратно
	// 			// enableScroll();
	// 			clearTimeout(failed);
	// 			setIsAutoScrolling(false);
	// 			resolve();
	// 		}

	// 		const scrollHandler = () => {
	// 			if(window.scrollY === posY) {
	// 				window.removeEventListener('scroll', scrollHandler);
	// 				resolvePromise();
	// 			}
	// 		}

	// 		if(window.scrollY === posY) {
	// 			resolvePromise();
	// 		}
	// 		else {
	// 			window.addEventListener('scroll', scrollHandler);
	// 		}
	// 	})
	// }

	// // Автоматический плавный скролл вниз до позиции начала второй анимации
	// function scrollDownToSecondAnim() {
	// 	console.log('АВТОМАТИЧЕСКИЙ ПЛАВНЫЙ СКРОЛЛ вниз до позиции начала второй анимации (должен быть один раз)');
	// 	// const posY = window.innerHeight * 2 * secondAnimStartPosY;
	// 	// const posY = window.innerHeight * 2;
	// 	const posY = store.isMobile ? window.innerHeight : window.innerHeight * 2; // TODO: Как нормально посчитать это значение????? Чтобы оно всегда соответствовало y=0.75 например
	// 	smoothlyScrollTo(posY).then(() => {
	// 		// setAutoScrollToNextAnim(false);
	// 		console.log('А СЮДААА???');
	// 		setInitAnimPhase(false);
	// 		setSecondAnimPhase(true);
	// 	});
	// }

	// // Автоматический плавный скролл вверх до позици начальной анимации
	// function scrollUpToInitAnim() {
	// 	console.log('Автоматический плавный скролл вверх до позици начальной анимации');
	// 	smoothlyScrollTo(0).then(() => {
	// 		// setAutoScrollToCurrentAnim(false);
	// 		setSecondAnimPhase(false);
	// 		setInitAnimPhase(true);
	// 	});
	// }

	useEffect(() => {
		// При маунте компонента проверяем позицию и обновляем состояния + добавляем onChange к скроллу
		if(scrollYProgress.get() > initAnimEndPosY) setSecondAnimPhase(true);
		else setInitAnimPhase(true);
		scrollYProgress.on('change', y => {
			// console.log('y: ' + y); // Можно использовать этот лог для наглядной демонстрации, при какой позиции какая анимация начинается
			setPosY(y);

			// TODO: Если теперь надо будет сделать автоматическую прокрутку, логику можно подсмотреть здесь
			// // В зависимости от направления скролла и текущей позиции решаем, нужно ли делать автоматический скролл куда-либо
			// if(!isAutoScrolling) {
			// 	if(scrollYProgress.getPrevious() < y) {
			// 		console.log('Попадаю ли я сюда?');
			// 		if(initAnimPhase && y >= 0.1 && y < 0.4) {
			// 			console.log('А сюда?');
			// 			scrollDownToSecondAnim()
			// 		};
			// 	}
			// 	else {
			// 		if(initAnimPhase && y <= 0.4) scrollUpToInitAnim();
			// 	}
			// }
		});
	}, [])

	useEffect(() => {
		// При обновлении текущей позиции прокрутки
		// Если прокрутка вниз
		if(scrollYProgress.getPrevious() < posY) {
			// Если фаза начальной анимации И текущая позиция стала больше границы окончания начальной анимации
			if(initAnimPhase && (posY > initAnimEndPosY)) {
				// Меняем начальную анимацию на вторую
				setInitAnimPhase(false);
				setSecondAnimPhase(true);
			};
		}
		// Если прокрутка вверх
		else {
			// Если фаза второй анимации И текущая позиция стала меньше границы окончания начальной анимации
			if(secondAnimPhase && (posY <= initAnimEndPosY)) {
				// Меняем вторую анимацию на начальную
				setSecondAnimPhase(false);
				setInitAnimPhase(true);
			}
		}
	}, [posY]);

	const headerY = useTransform(scrollYProgress, [0, 1], [0, -500]);
	const headerOpacity = useTransform(scrollYProgress, [0, 0.15, 0.25], [1, 1, 0]);
	const bgOpacity = useTransform(scrollYProgress, [0, 0.99, 1], [1, 1, 0]);
	const bgY = useTransform(scrollYProgress, [0.9, 1], [0, 300]);

	return (
		<motion.div
			ref={refContainer}
			className={clsx(style.first, secondAnimPhase && style.hideAfter)}
		>
			<div className={clsx(style.content, 'sticky-view-sticky')}>
				<motion.div
					className={clsx(style.info)}
					initial={{
						opacity: 0,
						y: 100,
					}}
					whileInView={{
						opacity: 1,
						y: 0,
					}}
					style={{
						visibility: secondAnimPhase ? 'hidden' : 'visible',
						y: store.isMobile ? undefined : headerY,
						opacity: headerOpacity,
					}}
				>
					<div className={style.title} >
						Безграничный мир кино и&nbsp;сериалов
					</div>

					<div className={style.subtitle}>
						Погрузитесь в мир кино и сериалов c умными телевизорами KION.
						Реалистичная цветопередача и качественное звучание
					</div>
				</motion.div>

				<MainFirstTvBlock
					initAnimate={initAnimPhase}
					secondAnimate={secondAnimPhase}
					positionY={scrollYProgress}
				/>

				{/* TODO BG */}
				<motion.div
					className={style.backgroundImg}
					style={{
						opacity: bgOpacity,
						visibility: props.bgVisible ? 'visible' : 'hidden',
						height: store.isMobile ? '70vh' : secondAnimPhase ? '45vh' : '70vh',
						y: bgY,
					}}
				/>
			</div>
		</motion.div>
	);
})
