import { motion, useScroll, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import styles from './screen.module.scss';
import { MainScreen8tv } from 'src/components/screens/8/8_tv';

export default function MainScreen8(props: { bgVisible: boolean; }) {
	const refContainer = useRef(null);
	const { scrollYProgress } = useScroll({ target: refContainer });

	const [yPos, setYpos] = useState(0);
	useEffect(() => {
		scrollYProgress.on('change', (y) => setYpos(y));
	}, [])

	const opacity = useTransform(scrollYProgress, [0, 0.15, 0.9, 1], [0, 1, 1, 0]);

	return (
		<motion.div style={{ opacity: opacity }} ref={refContainer} className={styles.eight}>
			{/* TODO bg */}
			<motion.img
				className={styles.bg}
				src='/main/8/bg.svg'
				style={{
					zIndex: props.bgVisible ? '0' : '-1',
				}}
			/>
			<div
				className={'sticky-view-sticky'}
			>
				<MainScreen8tv
					initial={yPos < 0.33}
					firstItem={yPos >= 0.33 && yPos < 0.66}
					secondItem={yPos >= 0.66}
					scrollYProgress={scrollYProgress}
				/>
			</div>
		</motion.div>
	);
}
