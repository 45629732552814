import { MotionValue, motion, useTransform } from 'framer-motion';
import styles from './index.module.scss';

export default function MainScreen3Image(props: {
	scale: MotionValue<number>;
	opacity: MotionValue<number>;
	inline: boolean;
}) {
	const gap = useTransform(props.scale, [0.7, 1.1], [20, 60]);
	const opacity = useTransform(props.scale, [0, 1], [0, 1]);

	const scale1 = useTransform(props.scale, [0.7, 1.1], [1, 1.6]);
	const opacity1 = useTransform(props.scale, [0.8, 1], [1, 0]);

	const scale2 = useTransform(props.scale, [0.7, 1], [0.5, 1]);
	const opacity2 = useTransform(props.scale, [0.8, 0.9, 1], [0, 0, 1]);

	return (
		<motion.div
			className={styles.wrapper}
			style={{
				gap: gap,
				opacity,
				// scale: props.inline ? 0.7 : 1,
			}}
		>
			<motion.div
				className={styles.image}
				style={{ scale: props.inline ? 1.6 : scale1 }}
				animate={props.inline ? 'inline' : 'column'}
				variants={{
					inline: {
						x: '-135%',
						y: 30,
					}
				}}
			>
				<motion.img
					className={styles.kion}
					src="/main/3/kion.svg"
					style={{
						scale: scale2,
						opacity: props.inline ? 1 : opacity1,
					}}
				/>

				<motion.img
					src="/main/3/kion-colored.svg"
					className={styles.kionColored}
					style={{
						scale: scale2,
						opacity: opacity2,
					}}
				/>
			</motion.div>

			<motion.img
				src="/main/3/originals.svg"
				style={{ scale: scale1 }}
				animate={props.inline ? 'inline' : 'column'}
				variants={{
					inline: {
						x: '40%',
						y: -50,
					}
				}}
			/>
		</motion.div>
	);
}
