import React from 'react';
import s from './index.module.scss';

export default function TvTab1(props: {stats: {[x: string]: string}}) {

  return (
    <div className={s.wrapper}>
      {Object.keys(props.stats).map((key, idx) => (
        <React.Fragment key={idx}>
          <h2>{key}</h2>
          <p>{props.stats[key]}</p>
        </React.Fragment>
      ))}
      <small>
        Технические характеристики товара могут отличаться от указанных на сайте, 
        уточняйте технические характеристики товара на момент покупки и оплаты. 
        Вся информация на сайте о товарах носит справочный характер и не является публичной офертой 
        в соответствии с пунктом 2 статьи 437 ГК РФ. 
        Убедительно просим вас при покупке проверять наличие желаемых функций и характеристик.
      </small>
    </div>
  );
}