import s from './index.module.scss';
import {HeaderComponent} from 'src/components/header/header';
import FooterComponent from 'src/components/footer/footer';
import { ContextStore, useStoreCtx } from 'src/store';
import { Outlet } from 'react-router-dom';

export default function Layout() {
  const store = useStoreCtx()

  return (
    <ContextStore.Provider value={store}>
      <HeaderComponent/>
      <main className={s.main}>
       <Outlet/>
      </main>
      <FooterComponent/>
    </ContextStore.Provider>
  );
}