import { observer } from "mobx-react-lite";
import { useStoreCtx } from "src/store";
import Breadcrumbs from "../../components/breadcrumbs";
import OrdersBtn from "../../components/orders-btn/orders-btn";
import { ToggleableElement } from "../../components/toggleable-element/toggleable-element";
import s from './index.module.scss';

export const DevicesServiceCenterPage = observer(() => {
  const httpService = useStoreCtx().appStore

  return (
    <div className={s.wrapper}>    
      <div className={s.headerBlock}>
        <Breadcrumbs steps={[
          {label: 'Устройства', link: '/'},
          {label: 'Все устройства KION', link: '/catalog'},
          {label: 'Адреса сервисных центров и поддержки', link: '/'},
        ]}/>
        <OrdersBtn/>
      </div>    
      
      <div>
        <h1>Адреса сервисных центров и поддержки</h1>

        {httpService.jsonTemplate.common.serviceCenters.map((item, idx) => (
          <ToggleableElement key={idx} title={item.city} info={item.points}/>
        ))}

      </div>
    </div>
  );
})