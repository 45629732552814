import { MainScreen1 } from 'src/components/screens/1/screen';
import { MainScreen3 } from 'src/components/screens/3/screen';
import { MainScreen7 } from 'src/components/screens/7/screen';
import MainScreen8 from 'src/components/screens/8/screen';
import { useScroll, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import MainScreen11 from 'src/components/screens/11';
import { Outlet } from 'react-router-dom';

export default function IndexPage() {
  const refContainer = useRef(null);
  const { scrollYProgress } = useScroll({
    target: refContainer,
  });

  const opacity3Screen = useTransform(scrollYProgress, [0, 0.20, 0.25], [0, 0, 1]);
  const opacity11Screen = useTransform(scrollYProgress, [0, 0.85, 0.9], [0, 0, 1]);

  useEffect(() => {
    scrollYProgress.on('change', (y) => {
      if (y > 0.9) setBgStage('first');
      else if (y > 0.3) setBgStage('second');
      else setBgStage('first');
    });
  }, [])

  const [bgStage, setBgStage] = useState<'first' | 'second' | null>('first');

  return (
      <>        
        <Outlet/>
        
        <MainScreen1 bgVisible={bgStage === 'first'} />

        <MainScreen3 opacity={opacity3Screen}/>

        <MainScreen7/>

        <MainScreen8 bgVisible={bgStage === 'second'} />

        <MainScreen11 opacity={opacity11Screen}/>
      </>
  );
}