import clsx from "clsx";
import { useEffect, useState } from "react";
import Swiper, { Navigation, Thumbs } from "swiper";
import { IDeviceOption } from "../../pages/device";
import Button from "../button";
import s from './index.module.scss';

export default function WhiteBlock(props: {
  selectOptions: IDeviceOption[];
  selectedValue: string | null,
  selectLabel: string,
}) {
  useEffect(() => {
    const sliderTv = new Swiper("." + s["slider-tv"], {
      slidesPerView: 'auto',
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.tv-next',
        prevEl: '.tv-prev',
      },
      modules: [Navigation]
    });

    new Swiper("." + s["slider-big-tv"], {
      thumbs: {
        swiper: sliderTv,
      },
      modules: [Thumbs],
    });
  }, [])

  const [isSelectOpen, setIsSelectOpen] = useState(false)

  const isSelectVisible: boolean = props.selectOptions?.length > 1
  const [selectedValue, setSelectedValue] = useState(props.selectOptions.find((x) => x.value === props.selectedValue)?.value || props.selectOptions[0].value);
  const selectedOption = props.selectOptions?.find(x => selectedValue === x.value) || null;  

  function openSelect() {
    setIsSelectOpen(!isSelectOpen)
  }

  return (
    <div className={s['block-white']}>
      <div className={s['left-bw']}>
        <div className={clsx('swiper-container', s['swiper-container'], s['slider-big-tv'])}>
          <div className={clsx('swiper-wrapper', s['swiper-wrapper'])}>
            {selectedOption?.images.main.map((img, idx) => (
              <div key={idx} className={clsx('swiper-slide', s['swiper-slide'])}>
                <img src={img} alt="Товар"/>
              </div>
            ))}
          </div>
        </div>

        {/* thumbsslider */}
        <div className={clsx('swiper-container', s['swiper-container'], s['slider-tv'])}>
          <div className={clsx('swiper-wrapper', s['swiper-wrapper'])}>
            {selectedOption?.images.thumb.map((img, idx) => (
              <div key={idx} className={clsx('swiper-slide', s['swiper-slide'])}>
                  <img src={img} alt="Товар с другого ракуса"/>
              </div>
            ))}
          </div>
          <div className={clsx(s['swiper-button-next'], 'tv-next')} />
          <div className={clsx(s['swiper-button-prev'], 'tv-prev')} />
        </div>
      </div>

      <div className={s['right-bw']}>
        <h1 className={s.h1}>
          {selectedOption?.title}
        </h1>
        {isSelectVisible && (
          <>
            <p className={s['little-p']}>
              {props.selectLabel}
            </p>

            <div
              className={s['select_mate']}
              data-mate-select="active"
              data-selec-open={isSelectOpen}
            >
              <select>
                {props.selectOptions.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                ))}
              </select>

              <p 
                className={s.selecionado_opcion}
                onClick={openSelect}
              >
                { selectedOption?.label }
              </p>

              <span 
                className={s.icon_select_mate}
                onClick={openSelect}
              >
                <svg xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8">
                  <path d="M5.012,9A1,1,0,0,0,4.3,10.706h0l3.129,3.1C9.583,15.934,10.661,17,12,17s2.417-1.066,4.573-3.2l3.129-3.1A1,1,0,0,0,18.987,9a1.016,1.016,0,0,0-.714.293l-3.129,3.1a28.045,28.045,0,0,1-2.389,2.223c-.517.39-.7.39-.752.39H12c-.054,0-.234,0-.752-.39a28.043,28.043,0,0,1-2.389-2.223l-3.129-3.1h0A1.016,1.016,0,0,0,5.012,9Z" transform="translate(-4 -9)" />
                </svg>
              </span>

              <div className={s.cont_list_select_mate}>
                <ul 
                  className={s.cont_select_int}
                  style={{height: isSelectOpen ? '270px' : '0'}}
                >
                  {props.selectOptions.map(option => (
                    <li
                      key={option.value} 
                      className={s.active} 
                      value={option.value}
                      onClick={() => {
                        setSelectedValue(option.value);
                        openSelect();
                      }}
                    >
                      <div>{ option.label }</div>
                      <div className={s.info}>{ option?.model }</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}

        <div className={s.specifications}>
          {selectedOption?.specs.map((item, idx) => (
            <div key={idx} className={s['block-sp']}>
              <div>{ item.label }</div>
              <div className={s.rt}>
                {item.value}
              </div>
            </div>
          ))}
        </div>

        <div className={s.priceBlock}>
          {selectedOption?.gift ? (
            <div className={s.gift}>{ selectedOption?.gift }</div>
          ) : (
            <div></div>
          )}
          
          {selectedOption?.price && (
            <p className={s.price}>
              {selectedOption?.price}
            </p>
          )}
        </div>

        {selectedOption?.buttons.map((btn) => (
          <Button 
            style={{ marginTop: 12 }}
            url={btn.url} 
            textColor={btn.textColor}
            backgroundColor={btn.backgroundColor}
          >
            {btn.text}
          </Button>
        ))}
      </div>
    </div>
  )
}
